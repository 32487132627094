import { Flex } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import { Button, Subheading, Title } from 'ui';
import { colors } from 'ui/theme';
import Layout from '../components/layout';
import { useUIColor } from '../hooks/use-ui-color';

// eslint-disable-next-line react/function-component-definition
export default function Home() {
  const { setColor } = useUIColor();
  const color = colors.pink;

  useEffect(() => {
    setColor(null);
  }, [setColor]);

  return (
    <Layout>
      <Flex
        w="auto"
        h="100vh"
        align="center"
        justify="center"
        flexDir="column"
        gap={2}
        px={4}
        pb={10}
      >
        <Title as="h1" color={color.primary}>
          404
        </Title>
        <Subheading size="sm" as="h2" color={color.primary}>
          Error
        </Subheading>
        <Subheading size="sm" as="h2" color={color.primary}>
          This Page Does Not Exist
        </Subheading>
        <Link to="/">
          <Button
            iconName="next"
            iconPosition="right"
            justify="space-between"
            gradientBg
            borderTrRadius={0}
            borderBlRadius={0}
            colorId="pink"
          >
            Return Home
          </Button>
        </Link>
      </Flex>
    </Layout>
  );
}
